<template>
  <div>
    <b-button v-b-toggle.sidebar-1
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="md"
      class="ml-1 mt-1"
      v-if="isPrincipal() || can('Create Bordereau')"
      @click="requestAddView"
    >
      <feather-icon icon="PlusIcon" />
      <span class="align-middle text-uppercase"  >AJOUTER UN Bordereau</span>
    </b-button>
    <b-sidebar id="sidebar-1" bg-variant="white" ref="sidebar" sidebar-class="sidebar-lg" shadow backdrop no-header right>
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder" v-if="bordID!=null">Modifier le Bordereau </h5>
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder" v-else>AJOUTER UN Bordereau </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="bordereauExterneComposeRules" v-slot="{ invalid }">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" ref="addForm" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
          <h4>ESTIMATION/PREVISIONNEL</h4>
          <b-form-group label="Montant" label-for="montant">
              <validation-provider #default="{ errors }" name="Montant">
                <cleave min="0" id="montant" v-model.trim="bordereau.montant"  placeholder="Montant" :state="errors.length > 0 ? false : null" class="form-control" :options="options.number" :raw="false"/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
          </b-form-group>
          <h4>BORDEREAU</h4>           
            <b-form-group label="Compagnie d'assurance*" label-for="compagnies">
              <validation-provider #default="{ errors }" name="compagnie" rules="required" >
                <v-select v-model="selectedAssureur"  id="compagnie" :state="errors.length > 0 ? false : null" :close-on-select="true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :getOptionKey="assureur => assureur.value + assureur.text" :options="compagnieOptions" :reduce="assureur => assureur.value" label="text" placeholder="assureur"> 
                  <template #option="{ text }">
                    <span class="ml-50"> {{ text }}</span>
                  </template>
                  <div slot="no-options">Aucun assureur disponible.</div>
                </v-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Date de début de période*" label-for="date-creation">
              <validation-provider #default="{ errors }" name="date de création" rules="required" >
                <flat-pickr id="date-creation" v-model="bordereau.date_debut" class="form-control" placeholder="Date de création" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Date de début de période*" label-for="date-debut">
              <validation-provider #default="{ errors }" name="date de debut" rules="required">
                <flat-pickr id="date-debut" v-model="bordereau.date_debut" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date d'effet" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date de fin de période*" label-for="date-fin">
              <validation-provider #default="{ errors }" name="date de fin" rules="required">
                <flat-pickr id="date-fin" v-model="bordereau.date_fin" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date d'effet" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }} </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Date de fin de période*" label-for="date-fin">
              <validation-provider #default="{ errors }" name="date de création" rules="required">
                <flat-pickr id="date-fin" v-model="bordereau.date_fin" class="form-control" placeholder="Date de création" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <div>
              <h4>Import fichier</h4>
            <!-- <b-form-group label="Type de bordereau*" label-for="typeB">
              
              <validation-provider #default="{ errors }" name="typeB" >
                <b-form-select v-model="bordereau.typeBordereau" :options="typeBordereauOptions" id="typeBordereau" >

                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group> -->
            <b-form-group  class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Bordereau'" @click="openBordereauFile">
                      <b-form-file type="file" id="bordoreauFile" class="d-none" v-model="bordereau.fileBordereau" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Bordereau" :value="bordereau.fileBordereau ? bordereau.fileBordereau.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>
              </validation-provider>
              <span>
              <b-alert show variant="warning"  class="p-1 text-left">
                <feather-icon icon="AlertCircleIcon" size="15" />
                <span>
                La fonctionnalité d’importation a été réalisée sur un nombre restreint de fichiers de commissions. Si celle-ci ne fonctionne pas avec votre fichier, merci de l’envoyer au format anonymisé à l’adresse mail : support@bubble-in.com. Il sera intégré lors de la prochaine mise à jour
                </span>
              
              </b-alert>
              </span>
              </b-form-group>
          </div>
            <h4>Encaissement</h4>
            <b-form-group label="Banque" label-for="num_devis">
              <validation-provider #default="{ errors }" name="Banque" >
                <b-form-input v-model="bordereau.banque.libelle" id="banque" placeholder="Banque" :state="errors.length > 0 ? false : null" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Date d’encaissement" label-for="date-creation">
              <validation-provider #default="{ errors }" name="date de création">
                <flat-pickr id="date-encaiss" v-model="bordereau.date_encaissement" class="form-control" placeholder="Date d’encaissement" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Montant encaissé" label-for="Montant encaissé">
              <validation-provider #default="{ errors }" name="Montant">
                <cleave  min="0" id="montant_encaisse" v-model.trim="bordereau.montant_encaissement"  placeholder="Montant Encaisse" :state="errors.length > 0 ? false : null"  class="form-control" :options="options.number" :raw="false"/>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
          </b-form-group>
          <div class="d-flex mt-2" v-if="bordereau.fileBordereau!=null">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="invalid" v-b-modal.modal-center class="mr-2" @click="saveData"> Enregistrer </b-button>
          </div>
          <div class="d-flex mt-2" v-else>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="invalid" class="mr-2" @click="saveData"> Enregistrer </b-button>
          </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-modal size="lg" centered  id="modal-center" ref="modal-import-base-client"
    hide-footer>
      <b-overlay :show="showLoading" no-wrap />
      <div class="container">
    <div class="row">
        <div class="col">
            Résultat de l'importation :
        </div>
      </div>
    <div class="row">
      <div class="col">
        <span style="color:green;line-height:18px" class="pt-2" v-if="stats.saved>0">{{stats.saved}} <span v-if="stats.notsaved==1">ligne insérée</span> <span v-else>lignes insérées</span></span>
      </div>
      <div class="col">
        <span style="color:black;line-height:18px" class="pt-2"><b>Total importé: {{Number(stats.import).toFixed(2)}} € </b></span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span style="color:red;line-height:18px" class="pt-2" v-if="stats.notsaved>0"> {{ stats.notsaved }} <span v-if="stats.notsaved==1">ligne invalide</span> <span v-else>lignes invalides</span></span>
      </div>
      <div class="col">
        <span style="color:black;line-height:18px" class="pt-2"><b>Total du bordereau: {{Number(stats.total).toFixed(2)}} € </b></span>
      </div>
    </div>
    <div class="row">
        <div class="col">
            <span style="color:black;line-height:18px" class="pt-2" v-if="stats.withoutContrats>0"> <b>{{ stats.withoutContrats }}</b> <span v-if="stats.withoutContrats==1">contrat non présent</span> <span v-else>contrats non présents</span> dans “Mes contrats”</span>
        </div>
        <div class="col">
            <span style="color:black;line-height:18px" class="pt-2"><b>Ecart: {{Number(stats.total-stats.import).toFixed(2)}} € </b></span>
        </div>
      </div>
    <div class="row">
      <div class="col">
        <span style="color:black;line-height:18px" class="pt-2"> Vous pouvez télécharger le fichier contenant les lignes non insérées pour réajustement.</span>
      </div>
      <div class="col" v-if="stats.notsaved>0">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="downloadMissingData"> TELECHARGER </b-button>
      </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="alert alert-danger" role="alert">
                L’import d’un bordereau doit être systématiquement complet. Tout nouvel import écrasera l’intégralité de ce qui a été précédemment importé.
            </div>
        </div>
    </div>
  </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { BOverlay,BAlert, BBadge, BAvatar, BModal,BButton, BSidebar, VBToggle, BCardText, BCard, BFormGroup, BFormFile, BFormInput, BFormTextarea, BFormCheckbox, BForm, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback, BFormSelect, BFormSelectOption, VBTooltip, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import exportFromJSON from "export-from-json";
import Cleave from 'vue-cleave-component'
export const excelParser = () => {
  function exportDataFromJSON(data, newFileName, fileExportType) {
    if (!data) return;
    try {
      const fileName = newFileName || "exported-data";
      const exportType = exportFromJSON.types[fileExportType || "xls"];
      exportFromJSON({ data, fileName, exportType });
    } catch (e) {
      throw new Error("Parsing failed!");
    }
  }

  return {
    exportDataFromJSON
  };
};
export default {
    components: {
    BBadge,
    BAlert,
    BInputGroup,
    VBTooltip,
    BOverlay,
    BAvatar,
    BForm,
    BFormFile,
    BFormSelectOption,
    BFormGroup, 
    vSelect,
    BFormInput,
    BFormSelect,
    flatPickr,
    BButton,
    BSidebar,
    BInputGroupPrepend,
    BModal,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    Cleave,
    moment
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props:{
    compagnieOptions: {
      type: Array,
      default: [],
      required: false
    },
    bordID:null
    // typeBordereauOptions: {
    //   type: Array,
    //   default: [],
    //   required: true
    // },
  },
  created(){
    this.$store.dispatch('clearData')
  },
  data(){
    return{
      showLoading:false,
      showResult:false,
      stats:{},
      hideFooter:false,
      invalidLign:{},
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: ' '
        },
        percent: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          numeralPositiveOnly: true,
        }
      },
      bordereau:{
        montant:null,
        nom:"",
        compagnie:null,
        date_debut:null,
        date_fin:null,
        typeBordereau:null,
        banque:null,
        date_encaissement:null,
        montant_encaisse:null,
        fileBordereau:null,
        banque:{libelle:null},
      },
      currentUser:JSON.parse(localStorage.getItem('userData')),
      selectedAssureur:null,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      requestedAction:"add"
    }
  },
  watch:{
    bordID(){
      if(this.bordID)
        this.initData()
      else{
        this.$refs.bordereauExterneComposeRules.reset()
        this.requestAddView(this)
      }
    } 
  },
  methods: {
    hideSideBare() {
      this.$refs.addBordereau.hide()
    },
    initData(){
      this.actionDemande="modify"
      this.resetBordereau()
      this.showLoading=true
      this.$http.get('/bordereau/getBordereau/'+this.bordID)
      .then(res=>{
        if(res.data){
          this.bordereau=res.data
          this.bordereau.date_debut=moment(res.data.date_debut).lang('fr').format('DD/MM/YYYY')
          this.bordereau.date_fin=moment(res.data.date_fin).lang('fr').format('DD/MM/YYYY')
          this.bordereau.date_encaissement=moment(res.data.date_encaissement).lang('fr').format('DD/MM/YYYY')
          this.selectedAssureur=this.compagnieOptions.find(item=> item.value==this.bordereau.assureur_id)
          if(res.data.banque==null)
          this.bordereau.banque={libelle: ''} 
          this.selectedAssureur=this.selectedAssureur.value
          this.showLoading=false
        }
         
      })
    },
    openBordereauFile: () => {
      document.getElementById('bordoreauFile').click()
    },
    downloadMissingData(){
      excelParser().exportDataFromJSON(this.stats.invalid, null, null);
    },
    getFileNameWithoutExtension(filename) {
      return filename.substr(0, filename.lastIndexOf('.'));
    }, 
    saveData(){
      const isValid =this.$refs.bordereauExterneComposeRules.validate();
      const formData = new FormData()
      if(!isValid){
        this.showLoading = false
        this.messageToast("les champs incomplets", 'Erreur', "danger")
        console.error(err)
      }
      else{
        this.showLoading = true
        if(this.bordereau.fileBordereau!=null){
          let nameOfFile = this.bordereau.fileBordereau.name;
          this.bordereau.nom = this.getFileNameWithoutExtension(nameOfFile)
        }
        console.log(this.bordereau.nom);
        formData.append('id', this.checkIsNull(this.bordereau.id))
        formData.append('nom', this.checkIsNull(this.bordereau.nom))
        formData.append('assureur_id', this.checkIsNull(this.selectedAssureur))
        formData.append('nom_assureur', this.checkIsNull(this.compagnieOptions.find(item => item.value == this.selectedAssureur)).text)
        formData.append('date_debut', this.checkIsNull(this.bordereau.date_debut))
        formData.append('montant', this.checkIsNull(this.bordereau.montant))
        formData.append('date_fin', this.checkIsNull(this.bordereau.date_fin))
        // formData.append('type_bordoreau', this.checkIsNull(this.bordereau.typeBordereau))
        formData.append('file_bordoreau', this.checkIsNull(this.bordereau.fileBordereau))
        formData.append('banque', this.checkIsNull(this.bordereau.banque.libelle))
        formData.append('date_encaissement', this.checkIsNull(this.bordereau.date_encaissement))
        formData.append('courtier_id', this.checkIsNull(this.currentUser.courtier_user[0].courtier_id))
        formData.append('montant_encaissement', this.checkIsNull(this.bordereau.montant_encaissement))
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        this.$http.post('/bordereau/add',formData, config)
        .then(res=>{
          if(res.data){
            if(this.actionDemande=="modify")
            this.messageToast("Bordereau a été modifier avec succes", 'Success', 'success')
            else 
            this.messageToast("Bordereau a été ajouter avec succes", 'Success', 'success')
            this.$refs.bordereauExterneComposeRules.reset()
            this.showLoading = false
            this.$emit('refresh', null)
            this.stats=res.data
            this.$refs.sidebar.hide()
          }
        }).catch(err=>{
          this.showLoading = false
          if(this.actionDemande=="modify")
            this.messageToast("Le est fichier incompatible mais le bordereau à été bien modifié", 'Erreur', 'warning')
          else 
            this.messageToast("Le est fichier incompatible mais le bordereau à été bien ajouté", 'Erreur', 'warning')
          this.$refs.bordereauExterneComposeRules.reset()
          this.$emit('refresh', null)
          this.$refs.sidebar.hide()
        })
        requestAnimationFrame(() => {
          this.$refs.bordereauExterneComposeRules.reset()
        });
        
      }
    },
    requestAddView(event){
      this.resetBordereau()
      this.actionDemande="add"
      this.$emit('addRequest');
      this.selectedAssureur=null
      this.bordereau.fileBordereau=null
      this.$refs.bordereauExterneComposeRules.reset()
    },
    resetBordereau(){
      this.bordereau={
        montant:null,
        compagnie:null,
        date_debut:null,
        date_fin:null,
        typeBordereau:null,
        banque:null,
        date_encaissement:null,
        montant_encaisse:null,
        fileBordereau:null,
        banque:{libelle:null},
      }
    }
    // checkAction(){
    //   if(this.actionDemande=="modify"){
    //     this.requestedAction=this.actionDemande
    //     this.selectedAssureur=this.compagnieOptions.find(item=> item.text.localeCompare(this.bordereau.nom_com_assureur) ==0)
    //     this.selectedAssureur=this.selectedAssureur.value
    //   }else{
    //     this.selectedAssureur=null
    //     this.bordereau={
    //       montant:null,
    //       compagnie:null,
    //       date_creation:null,
    //       id:null,
    //       date_fin:null,
    //       // typeBordereau:null,
    //       libelle:null,
    //       date_encaissement:null,
    //       montant_encaisse:null,
    //       fileBordereau:null,
    //     }      
    //     requestAnimationFrame(() => {
    //       this.$refs.bordereauExterneComposeRules.reset()
    //     });
        
    //   }
    // },

  },
}
</script>

<style>
.img-proche
{
    padding:5% 10%;
}
@media(max-width:768px)
{
    .img-proche
    {
        margin-top:50px;
        padding: 0;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
