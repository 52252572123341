<template>
    <div>
      <b-sidebar id="sidebar-document-bordereau" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarDocument"  shadow backdrop no-header right>
        <template #default="{ hide }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>
  
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
          <validation-observer ref="documentComposeRules">
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
              <!-- clients list -->
              <b-form-group class="mt-2" label-for="h-document">
                <validation-provider #default="{ errors }" name="document" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                        <b-form-file type="file" id="fileautredocument" class="d-none" v-model="borderauFile" />
                        <feather-icon icon="UploadCloudIcon" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input disabled placeholder="Choisissez un document" :value="borderauFile ? borderauFile.name : ''" :state="errors.length > 0 ? false : null" />
                  </b-input-group>
                  </validation-provider>
              </b-form-group>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordDocument"> Enregistrer </b-button>
              </div>
            </b-form>
          </validation-observer>
        </template>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import { BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip, BAvatar, } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import vSelect from 'vue-select'
  // eslint-disable-next-line import/named
  import documentService from '@/shared/services/documentService'
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      BButton,
      BSidebar,
      BFormGroup,
      BFormInput,
      BForm,
      BFormInvalidFeedback,
      BInputGroup,
      BInputGroupPrepend,
      BFormSelect,
      BFormSelectOption,
      BFormTextarea,
      BFormFile,
      vSelect,
      BAvatar,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContentVue,
      // Form Validation
      ValidationProvider,
      ValidationObserver
    },
    directives: {
      'b-tooltip': VBTooltip,
      'b-toggle': VBToggle,
      Ripple
    },
    props: {
      bordereau: {
        type: Object,
        default: {},
        required: true
      },
      courtierId: {
        type: Number,
        default: 0,
        required: true
      },
      userId: {
        type: Number,
        default: 0,
        required: true
      },
    },
    data() {
      return {
        currentUser:JSON.parse(localStorage.getItem('userData')),
        borderauFile:null,
      }
    },
    created() {
      
    },
     mounted() {

    },
    methods: {
      // Methode additional
      hideSideBare() {
        this.borderauFile=null
        this.$refs.sideBarDocument.hide()
        this.$refs.documentComposeRules.reset()
        
      },
      openToSelectFile: () => {
        document.getElementById('fileautredocument').click()
      },
      messageToast(text, title, variant) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant
          }
        })
      },
      // record Data document courtier
      recordDocument() {
        this.$refs.documentComposeRules.validate().then(success => {
          if (success) {
            // Set formData
            const formData = new FormData()
            // Append the rest of your form data
            formData.append('bordereau_id', this.bordereau.id)
            formData.append('user_id', this.userId)
            formData.append('courtier_id', this.courtierId)
            formData.append('nom', this.borderauFile.name)
            formData.append('description',this.borderauFile.name)
            formData.append('file', this.borderauFile)
            // send request
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
              .post('/document/createNewBordereauCourtier', formData, config)
              .then(res => {
                if (res.data.success) {
                //   this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                //   this.clearData()
                this.hideSideBare()
                this.messageToast(res.data.message, 'Succès', 'success')
                } 
              })
              .catch(err => {
                this.messageToast('Les fichiers autorisés sont : images, excel et PDF.', 'Fichier Incompatible!', 'error')
              })
          }
        })
      },
    },
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  