var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sideBarDocument",attrs:{"id":'sidebar-document-' + _vm.id,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v("Nouveau document")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"documentComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),_c('b-form-group',{attrs:{"label":"Famille de document*","label-for":"h-famille"}},[_c('validation-provider',{attrs:{"name":"famille de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-famille-autre-document","disabled":"","options":_vm.optionsFamille,"state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.changeListoptionType()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.famille),callback:function ($$v) {_vm.famille=$$v},expression:"famille"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type de document*","label-for":"h-type"}},[_c('validation-provider',{attrs:{"name":"type de document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"h-type-autre-document","options":_vm.optionsType,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Choisissez --")])]},proxy:true}],null,true),model:{value:(_vm.composeData.idType),callback:function ($$v) {_vm.$set(_vm.composeData, "idType", $$v)},expression:"composeData.idType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isAutre)?_c('b-form-group',{attrs:{"label":"Nom du document*","label-for":"h-nom-document"}},[_c('validation-provider',{attrs:{"name":"nom du document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-nom-document-autre-document","placeholder":"Nom du document","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.nom),callback:function ($$v) {_vm.$set(_vm.composeData, "nom", $$v)},expression:"composeData.nom"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Date début*","label-for":"date-debut"}},[_c('validation-provider',{attrs:{"name":"date début","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-debut-coformite","config":_vm.configDate,"placeholder":"date début"},model:{value:(_vm.composeData.dateDebut),callback:function ($$v) {_vm.$set(_vm.composeData, "dateDebut", $$v)},expression:"composeData.dateDebut"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Date fin*","label-for":"date-fin"}},[_c('validation-provider',{attrs:{"name":"date fin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"date-fin-coformite","config":_vm.configDate,"placeholder":"date fin"},model:{value:(_vm.composeData.dateFin),callback:function ($$v) {_vm.$set(_vm.composeData, "dateFin", $$v)},expression:"composeData.dateFin"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.hasFormation)?_c('b-form-group',{attrs:{"label":"Objet de la formation","label-for":"objet"}},[_c('b-form-input',{attrs:{"id":"objet","trim":"","placeholder":"Objet de la formation"},model:{value:(_vm.composeData.objet),callback:function ($$v) {_vm.$set(_vm.composeData, "objet", $$v)},expression:"composeData.objet"}})],1):_vm._e(),(_vm.hasFormation)?_c('b-form-group',{attrs:{"label":"Nombre de minutes*","label-for":"nbr_heure"}},[_c('validation-provider',{attrs:{"name":"Nombre de minutes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nbr_heure","type":"number","placeholder":"Nombre de minutes","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.nb_heures),callback:function ($$v) {_vm.$set(_vm.composeData, "nb_heures", $$v)},expression:"composeData.nb_heures"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(!_vm.isInterfaceCollaborateur)?_c('validation-provider',{attrs:{"name":"collaborateur"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null,"label":"Collaborateur","label-for":"collaborateur"}},[_c('v-select',{staticClass:"flex-grow-1",attrs:{"close-on-select":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.collaborateurOptions,"input-id":"collaborateur","label":"nom","placeholder":"collaborateur"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var nom = ref.nom;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"src":avatar,"size":"sm"}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(nom))])]}}],null,true),model:{value:(_vm.composeData.collaborateur),callback:function ($$v) {_vm.$set(_vm.composeData, "collaborateur", $$v)},expression:"composeData.collaborateur"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Aucune collaborateur disponible.")])]),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),_c('b-form-group',{staticClass:"mt-2",attrs:{"label-for":"h-document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Parcourir le fichier'),expression:"'Parcourir le fichier'",modifiers:{"hover":true}}],staticClass:"btn-icon",attrs:{"variant":errors.length > 0 ? 'outline-danger' : 'outline-primary'},on:{"click":_vm.openToSelectFile}},[_c('b-form-file',{staticClass:"d-none",attrs:{"type":"file","id":"fileautredocument"},model:{value:(_vm.composeData.file),callback:function ($$v) {_vm.$set(_vm.composeData, "file", $$v)},expression:"composeData.file"}}),_c('feather-icon',{attrs:{"icon":"UploadCloudIcon"}})],1)],1),_c('b-form-input',{attrs:{"disabled":"","placeholder":"Choisissez un document","value":_vm.composeData.file ? _vm.composeData.file.name : '',"state":errors.length > 0 ? false : null}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordDocument}},[_vm._v(" Enregistrer ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }