<template>
  <div >
    <b-card :id="'bordereau-card' + index" no-body class="card-revenue-budget text-dark" bg-variant="gradient-secondary" style="background-image: linear-gradient(45deg, #ffffff, #ffffff00)">
      <b-row class="mx-0">
        <b-col  class="revenue-report-wrapper">
          <b-row>
            <b-col cols="12" class="mb-2">
              <span class="h3 font-weight-bolder">{{ bordereau.assureur.nom_com_assureur}}</span>          
              <div  class="mt-1">
                <small class="text-muted d-block">Période {{ changeFormatDateShortNormal(bordereau.date_debut)}} - {{ changeFormatDateShortNormal(bordereau.date_fin)}}</small>
              </div>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col md="4" sm="12">
                  <div class="d-inline">
                    <span v-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) && Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.importer).toFixed(2) && Number(bordereau.total_bordereau).toFixed(2)==Number(bordereau.importer).toFixed(2) " class="bullet bullet-success bullet-sm mr-1"/>
                    <span v-else-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) || Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.importer).toFixed(2) || Number(bordereau.total_bordereau).toFixed(2)==Number(bordereau.importer).toFixed(2) " class="bullet bullet-warning bullet-sm mr-1"/>
                    <span v-else class="bullet bullet-danger bullet-sm mr-1"/>
                    
                    <span v-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) && Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.importer).toFixed(2) && Number(bordereau.total_bordereau).toFixed(2)==Number(bordereau.importer).toFixed(2) " > Rapproché </span>
                    <span v-else-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) || Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.importer).toFixed(2) || Number(bordereau.total_bordereau).toFixed(2)==Number(bordereau.importer).toFixed(2) " > Partiellement rapproché </span>
                    <span v-else>Non rapproché </span>
                  </div>
                </b-col>
                <b-col  cols="12">
                    <b-row class="d-flex flex-row-reverse" v-if="(bordereau.missing)!=null">
                      <div class=" d-inline float-right text-danger" v-if="(bordereau.missing.nombre)!=null && (bordereau.missing.nombre)>0" >
                        <span> Contrats absents du bordereau : {{bordereau.missing.nombre?bordereau.missing.nombre:0}}</span>
                      </div>
                    </b-row>
                    <b-row class="d-flex flex-row-reverse" v-if="(bordereau.without)!=null">
                      <div class="d-inline float-right text-danger" v-if="(bordereau.without.nombre)!=null && (bordereau.without.nombre)>0" >
                        <span> Contrats manquants dans “Mes contrats” : {{bordereau.without.nombre?bordereau.without.nombre:0}}</span>
                      </div>
                    </b-row>
                  </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <div  class="mt-3 ml-3">
                  <span> Montant encaissé : {{bordereau.montant_encaissement ? Number(bordereau.montant_encaissement).toFixed(2).replace(".", ",") : '0'}} €</span>
                </div>
              </b-row>
            </b-col>
            <b-col>
              <b-row>
                <div  class="mt-3 ml-3">
                  <span> Total bordereau : {{bordereau.total_bordereau ? Number(bordereau.total_bordereau).toFixed(2).replace(".", ",") : '0'}} €</span>
                </div>
              </b-row>
            </b-col>
            <b-col >
              <b-row>
                <div  class="mt-3 ml-3">
                  <span> Total importé : {{bordereau.importer ? Number(bordereau.importer).toFixed(2).replace(".", ",") : '0'}} €</span>
                </div>
              </b-row>
            </b-col>
            <b-col >
              <b-row>
                <div  class="mt-3 ml-3">
                  <span> Estimation/Prévisionnel : {{Number(bordereau.montant).toFixed(2).replace(".", ",")}} €</span>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col  md="3" class="budget-wrapper p-0" style="background-color: #4d25bc">
          <b-list-group flush >
            <b-list-group-item v-if="isPrincipal() || can('Modify bordereau')" active class="p-0" >
              <b-button v-b-toggle.sidebar-1 v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="modifyAction()" class="rounded-0 p-1 m-0 text-left" block variant="primary"> <feather-icon icon="EditIcon" size="16" /> Modifier </b-button>
            </b-list-group-item>
              <b-list-group-item v-if="isPrincipal() || can('Show bordereau details')" active class="p-0" >
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="rounded-0 p-1 m-0 text-left" @click="openDetailsTab(bordereau.id)" block variant="primary"> 
                <feather-icon  icon="LockIcon" size="16" /> VOIR LE DETAIL </b-button>
              </b-list-group-item>
              <b-list-group-item v-if="isPrincipal() || can('Add document to bordereau')"  active class="p-0" >
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"  v-b-toggle.sidebar-document-bordereau @click="uploadFileAction" class="rounded-0 p-1 m-0 text-left" block variant="primary">
                 <feather-icon icon="PaperclipIcon" size="16" /> JOINDRE UN DOCUMENT </b-button>
              </b-list-group-item>
          </b-list-group>
        </b-col>

      </b-row>
      <!-- <b-col md="12" class="budget-wrapper p-0" style="background-color: #4d25bc">
        <app-collapse-item :title="'VOIR LE DETAIL'">
        <bordereau-details :bordereau-details="bordereau" />
      </app-collapse-item>      
      </b-col> -->


    </b-card>
  </div>
</template>
<script>
import { VBToggle,BImg, BDropdown, BDropdownItem, BPagination, BFormSelect, BButton, BOverlay, BCard, BInputGroup, BFormInput, BInputGroupAppend, BCardBody, BBadge, BRow, BCol, BFormRadioGroup, BFormRadio, BFormGroup, BListGroup, BListGroupItem } from 'bootstrap-vue'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import store from '@/store/index'
    import { VueGoodTable } from 'vue-good-table'
    import Ripple from 'vue-ripple-directive'
    import BordereauDetails from './BordereauDetails.vue'
    import moment from 'moment';

    export default {
      components: {
        AppCollapse,
        AppCollapseItem,
        VueGoodTable,
        // BSV
        BImg,
        BButton,
        BDropdown,
        BDropdownItem,
        BFormSelect,
        BPagination,
        BCard,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BCardBody,
        BOverlay,
        BFormRadio,
        BBadge,
        BRow,
        BCol,
        BFormRadioGroup,
        BFormGroup,
        BListGroup,
        BListGroupItem,
        BordereauDetails,
        // UI
      },
      props:{
        assureurID: {
            type: Number,
            default: null,
            required: false
        },
        bordereau: {
            type: Object,
            default: null,
            required: true
        },
        courtierId: {
          type: Number,
          default: null,
          required: true
        },
        userId: {
          type: Number,
          default: null,
          required: true
        },
        index: {
          type: Number,
          default: null,
          required: false
        }
      },
      directives: {
        'b-toggle': VBToggle,
        Ripple
      },
      
      computed:{
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
            }
      },
      created(){
        // this.getStatsBordereau()
        // this.initDates()
      },
      watch:{
        // bordereau(){
        //   this.initDates()
        // }
      },
      data(){
        return {
          stats:{},
          dir: false,
          titleWithStyle: null,
          statutBordereau :null,
          document: {
              base64: null,
              name: null,
              nameToDownload: null,
              extensionDocument: null
          },
          selected: null,
          options: [
              { value: null, text: 'Action de masse' },
              { value: 'autre', text: ' --- ' }
          ],
          pageLength: 3,
          optionsCheckBox: [
              { text: 'Non', value: false },
              { text: 'Oui', value: true }
          ],
          formationColumns: [
              { label: 'Date', field: 'date', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
              { label: 'objet de la formation ', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
              // { label: 'NB heures', field: 'nbHeures', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
              { label: 'Au titre', field: 'auTitre', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
              { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
          ]
        }
      },
      methods:{
        messageToast(text, title, variant) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title,
              icon: 'BellIcon',
              text,
              variant
            }
          })
        },
        downloadDocument(base64, name) {
          if (base64) {
            const a = document.createElement('a')
            a.href = base64
            a.download = name
            a.click()
            this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
          } else {
            this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
          }
        },
        updateFormation(bordereau) {
          this.dataSelectedForEditFormation = collaborateur
          this.dataSelectOnlyOneFormation = formation
          this.isUpdateFormation = true
          this.$root.$emit('bv::toggle::collapse', 'sidebar-formation-collaborateur')
        },
        modifyAction(){
          this.$emit('modify', this.bordereau);

        },
        openDetailsTab(bordereauID){
          this.$router.push({ name: 'bordereaux-details', params: { id: bordereauID } })
        },
        openBordereauFile: () => {
      document.getElementById('bordoreauFile').click()
        },
        uploadFileAction(){
          this.$emit('upload', this.bordereau)
        },
        // getStatsBordereau(){
        //   this.$http.post('/bordereau/getStatsBordereau',{assureur_id:this.bordereau.assureur_id,bordereau_id:this.bordereau.id})
        //   .then(res=>{
        //     if(res.data){
        //       this.stats=res.data
        //     }
        //   })
        // },
        // initDates(){
        //   this.bordereau.date_debut=this.changeFormatDateShortNormal(this.bordereau.date_debut)
        //   this.bordereau.date_fin=this.changeFormatDateShortNormal(this.bordereau.date_fin)
        // }
      }

    }
</script>
<style scoped>
.bordereau-card::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000029;
    z-index: 99;
}
</style>
