<template>
  <div>
    <b-sidebar :id="'sidebar-document-' + id" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarDocument" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau document</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="documentComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <b-overlay :show="showLoading" no-wrap />
            <b-form-group label="Famille de document*" label-for="h-famille" >
              <validation-provider #default="{ errors }" name="famille de document" rules="required">
                <b-form-select id="h-famille-autre-document" disabled v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Type de document*" label-for="h-type">
              <validation-provider #default="{ errors }" name="type de document" rules="required">
                <b-form-select id="h-type-autre-document" v-model="composeData.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                  <template #first>
                    <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
             <b-form-group label="Nom du document*" label-for="h-nom-document"  v-if="isAutre">
            <validation-provider #default="{ errors }" name="nom du document" rules="required">
              <b-form-input id="h-nom-document-autre-document" v-model="composeData.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
            </b-form-group>
            <b-form-group label="Date début*" label-for="date-debut">
              <validation-provider #default="{ errors }" name="date début" rules="required">
                <flat-pickr id="date-debut-coformite" v-model="composeData.dateDebut"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="date début"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date fin*" label-for="date-fin">
              <validation-provider #default="{ errors }" name="date fin" rules="required">
                <flat-pickr id="date-fin-coformite" v-model="composeData.dateFin"
                            :class="errors.length > 0 ? 'is-invalid' : ''"
                            :config="configDate" class="form-control" placeholder="date fin"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="hasFormation"
              label="Objet de la formation"
              label-for="objet"
            >
                <b-form-input
                  id="objet"
                  trim
                  placeholder="Objet de la formation"
                  v-model="composeData.objet"
                />
            </b-form-group>

            <b-form-group
              v-if="hasFormation"
              label="Nombre de minutes*"
              label-for="nbr_heure"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Nombre de minutes"
                  rules="required"
                >
                <b-form-input
                  id="nbr_heure"
                  type="number"
                  placeholder="Nombre de minutes"
                  v-model="composeData.nb_heures"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          <validation-provider #default="{ errors }" name="collaborateur" v-if="!isInterfaceCollaborateur">
            <b-form-group :state="errors.length > 0 ? false : null" label="Collaborateur" label-for="collaborateur">
              <v-select v-model="composeData.collaborateur" :close-on-select="true"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="collaborateurOptions" class="flex-grow-1"
                input-id="collaborateur" label="nom" placeholder="collaborateur">
                <template #option="{ avatar, nom }">
                  <b-avatar :src="avatar" size="sm" />
                  <span class="ml-50"> {{ nom }}</span>
                </template>

                <template #selected-option="{ avatar, nom }">
                  <b-avatar :src="avatar" class="border border-white" size="sm" />
                  <span class="ml-50"> {{ nom }}</span>
                </template>
                <div slot="no-options">Aucune collaborateur disponible.</div>
              </v-select>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                              errors[0]
                            }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <b-form-group class="mt-2" label-for="h-document">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                      <b-form-file type="file" id="fileautredocument" class="d-none" v-model="composeData.file" />
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''" :state="errors.length > 0 ? false : null" />
                </b-input-group>

                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordDocument"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BInputGroup, BInputGroupPrepend, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, VBTooltip, BAvatar, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/named
import documentService from '@/shared/services/documentService'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    vSelect,
    BAvatar,
    flatPickr,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    id: {
      type: String,
      default: 'cabinet',
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: false
    },
    isInterfaceCollaborateur: {
      type: Boolean,
      default: false,
      required: false
    },
    collaborateurOptions: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
      required: false
    },
    documentConformiteAndNonConformite: {
      type: Array,
      default: () => [],
      required: false
    },
  },
  computed: {
    // optionsFamille() {
    //   return this.documentConformiteAndNonConformite.filter(function(el) {
    //       return el.value.id == 2;
    //   });
    // },
  },
  data() {
    return {
      showLoading: false,
      isAutre:false,
      showDate:false,
      configDate: {
      dateFormat: 'd/m/Y',
      allowInput: true,
      onKeyDown: (selectedDates, dateStr, instance, event) => {
        if (event.keyCode != 8) {
          const ele = document.activeElement
          let val = ele.value

          if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
            val += '/'
          }
          ele.value = val
          ele.dispatchEvent(new Event('input'))
        }
      }},
      required,
      famille: null,
      optionsType: [],
      typeSelected: null,
      composeData: {
        id: null,
        nom: null,
        description: '',
        idFamille: null,
        idType: null,
        file: null,
        courtier_id: this.courtierId,
        user_id: null,
        collaborateur: null,
        idDocumentConformite: null,
        dateDebut: null,
        dateFin: null,
        objet:null,
        nb_heures:null,
        isSendEmail: false,
      },
      documentConformiteDemande:[],
      hasFormation:false,
      isConformite:false
    }
  },
  created() {
    this.fetchDocumentConformite()
  },
  watch: {
    userId: {
      handler(val) {
        this.composeData.user_id = val
      }
    },
    'composeData.idType': {
      handler(val) {
        let foundDocConformite= this.documentConformiteDemande.find(e => e.typeId === val)

        if(foundDocConformite) {
          this.composeData.idDocumentConformite=foundDocConformite.id
          this.composeData.isSendEmail = !!foundDocConformite.isSendEmail
        }else{
          this.composeData.idDocumentConformite=null
          this.composeData.isSendEmail=false
        } 

        if(val==9){
          this.hasFormation=true
        }else{
          this.hasFormation=false
        }
        if(val !==23 && val !==41){
          this.isConformite=true
        }else{
          this.isConformite=false
        }
        if(val == 53 ){
          this.isAutre = true
          this.showDate=false
        }else{
           this.isAutre = false
          this.showDate=true

        }
      }
    },
    documentConformiteAndNonConformite: {
      deep:true,
      immediate:true,
      handler(val) {
        this.optionsFamille= val.filter(function(el) {
          return el.value.id == 2;
        });
        this.famille=this.optionsFamille[0].value
        this.changeListoptionType()
      }
    },
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarDocument.hide()
    },
    openToSelectFile: () => {
      document.getElementById('fileautredocument').click()
    },
    changeListoptionType() {
      if (this.famille) {
        this.composeData.idFamille = this.famille.id
        this.composeData.idType = null
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.idFamille = null
        this.composeData.idType = null
        this.optionsType = []
      }
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    fetchDocumentConformite() {
      this.documentConformiteDemande = []
      this.$http
          .get('/document_conformite/fetchDocumentConformite')
          .then(res => {
            if (res.data.success) {
              this.documentConformiteDemande = res.data.data

            } else {
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    clearData() {
      this.composeData.idDocumentConformite = null
      this.composeData.dateDebut = null
      this.composeData.objet = null
      this.composeData.nb_heures = null
      this.composeData.dateFin = null
      this.composeData.id = null
      this.composeData.nom = null
      this.composeData.description = ''
      this.composeData.idFamille = null
      this.composeData.idType = null
      this.composeData.file = null
      this.composeData.courtier_id = this.courtierId
      this.composeData.user_id = this.userId
      this.composeData.collaborateur = null
      this.typeSelected = null
      this.isConformite=false
      this.$refs.documentComposeRules.reset()
    },
    // record Data document courtier
    recordDocument() {
      this.$refs.documentComposeRules.validate().then(success => {
        if (success) {
          this.showLoading = true
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data
          formData.append('id', this.composeData.id)
          formData.append('nom', this.composeData.nom)
          formData.append('description', this.composeData.description)
          formData.append('idType', this.composeData.idType)
          formData.append('file', this.composeData.file)
          formData.append('courtier_id', this.courtierId)
          formData.append('id_document_conformite', this.composeData.idDocumentConformite)
          formData.append('isSendEmail', this.composeData.isSendEmail)
          formData.append('date_debut', this.composeData.dateDebut ? this.composeData.dateDebut : '')
          formData.append('date_fin', this.composeData.dateFin ? this.composeData.dateFin : '')
          formData.append('hasFormation', this.hasFormation)
          formData.append('objet', this.composeData.objet ? this.composeData.objet : '')
          formData.append('nb_heures', this.composeData.nb_heures ? this.composeData.nb_heures : '' )
          let collaborateurId = this.userId
          if(!this.isInterfaceCollaborateur)
            collaborateurId = this.composeData.collaborateur ? this.composeData.collaborateur.id : null
          console.log(this.composeData.isSendEmail);
          formData.append('user_id', collaborateurId)

          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/document/createNewDocumentCourtierCollaborateur', formData, config)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-document-courtier-returned', res.data.data, res.data.message)
                this.clearData()
                this.hideSideBare()
                this.showLoading = false
              } else {
                this.messageToast('une erreur est survenue.', 'Erreur', 'error')
                this.showLoading = false
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              this.showLoading = false
            })
        }
      })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
