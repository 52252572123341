<template>
  <b-modal id="modal-permission-collaborateur" ref="modal-permission-collaborateur" cancel-title="Fermer"
           cancel-variant="secondary" centered ok-title="Enregistrer" size="xl" @ok="recordPrivileges"
  >

    <template #modal-header="{ close }">
      <div>
        <h5>Droits {{ collaborateur.prenomCollaborateur + ' ' + collaborateur.nomCollaborateur }}</h5>
        <!-- <b-form-checkbox v-model="selectedAllParametres" class="custom-control-primary">
          <span class="text-primary"> Administrateur </span>
        </b-form-checkbox>
        <b-form-checkbox v-model="selectedAllParametresStandardUser" class="custom-control-primary">
          <span class="text-primary"> Utilisateur standard </span>
        </b-form-checkbox> -->
        <div class="demo-inline-spacing">
          <b-form-radio disabled v-if="(collaborateur.isPrincipal && !collaborateur.statut_juridique) || collaborateur.statut_juridique == 'Associé'" v-model="selectedAllParametres"  name="some-administrateur" value="Administrateur">Courtier</b-form-radio>
          <!-- <b-form-radio v-model="selectedAllParametres" name="some-utilisateur-standard" value="Utilisateur standard">Utilisateur standard</b-form-radio>-->
          <!-- <b-form-radio v-model="selectedAllParametres" name="some-courtier" value="Courtier">Courtier</b-form-radio> -->
          <b-form-radio disabled v-model="selectedAllParametres" v-if="collaborateur.statut_juridique == 'Salarié' || collaborateur.statut_juridique == 'Mandataire'" name="some-salarie-ou-mandataire" value="Salarie ou Mandataire">Salarié/Mandataire</b-form-radio>
          <b-form-radio disabled v-if="collaborateur.statut_juridique == 'Co-courtier' || collaborateur.statut_juridique == 'Indicateur'" v-model="selectedAllParametres" name="some-co-courtier-indicateur" value="Co-courtier ou Indicateur">Co-courtier/Indicateur</b-form-radio>
          <b-form-radio disabled v-model="selectedAllParametres" name="some-personnaliser" value="Personnaliser">Personnaliser</b-form-radio>
        </div>
      </div>

      <b-button class="btn-icon" size="sm" variant="gradient-secondary" @click="close()">
        <feather-icon icon="XIcon"/>
      </b-button>
    </template>
    <b-card-text>
      <!-- v-if="sizeWidth >= 994 ? vertical : null" -->
      <b-tabs :nav-wrapper-class="vertical ? 'nav-vertical' : ''" :vertical="vertical" nav-class="nav-left" start>
        <!-- loop interface -->

        <b-overlay :show="showLoading" no-wrap/>
        <b-tab v-for="(interfaceRow, i) in permissionsData" :key="'interface-tab-' + i">
          <template #title>
            <feather-icon :icon="interfaceRow.icon"/>
            <span>{{ interfaceRow.name }}</span>
          </template>
          <b-card-text v-if="interfaceRow.sub_interface">
            <b-alert show variant="primary">
              <div class="alert-body">
                <b-form-checkbox v-model="allSelectedByInterface" :value="interfaceRow" class="custom-control-primary"
                                 @change="toggleAllByInterface(interfaceRow)"
                >
                  <span class="text-primary">Sélectionner tous les droits <span class="text-lowercase"
                  > {{ interfaceRow.name }} </span> </span>
                </b-form-checkbox>
              </div>
            </b-alert>

            <!-- loop sub interface -->
            <app-collapse v-for="(subInterface, j) in interfaceRow.sub_interface" :key="'interface-accordion-' + i + j"
                          accordion
                          type="margin"
            >
              <app-collapse-item v-if="subInterface.privilege" :title="subInterface.name">
                <b-form-group>
                  <template #label>
                    <b-alert show variant="warning">
                      <div class="alert-body">
                        <b-form-checkbox v-model="allSelectedBySubInterface" :value="subInterface"
                                         class="custom-control-warning"
                                         @change="toggleAllBySubInterface($event, interfaceRow, allSelectedBySubInterface.filter(e => e.id === subInterface.id).length > 0, subInterface)"
                        >
                          <span class="text-warning"
                          > {{
                              allSelectedBySubInterface.filter(e => e.id === subInterface.id).length > 0 ? 'Désélectionnez' : 'Sélectionnez'
                            }} tous les droits sur <span class="text-lowercase"> {{ subInterface.name }} </span> </span>
                        </b-form-checkbox>
                      </div>
                    </b-alert>
                  </template>

                  <template>
                    <b-form-checkbox-group v-model="privilegeIds" :options="subInterface.privilege"
                                           class="mb-3" stacked text-field="text"
                                           value-field="id" @change="selectedAllParametres = 'Personnaliser'"
                    />
                  </template>
                </b-form-group>
              </app-collapse-item>
            </app-collapse>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BAlert,
  BButton,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormRadio,
  BModal,
  BOverlay,
  BRow,
  BTab,
  BTabs,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import AppCollapse from './app-collapse-permission/AppCollapse.vue'
import AppCollapseItem from './app-collapse-permission/AppCollapseItem.vue'

export default {
  components: {
    BButton,
    BCardText,
    BModal,
    BTabs,
    BTab,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BFormCheckbox,
    BFormCheckboxGroup,
    BAlert,
    BFormGroup,
    BOverlay,
    BFormRadio,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    userId: {
      type: Number,
      default: null,
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    collaborateur: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        attestationHonorabilite: false,
        curriculumVitae: false,
        idQualification: null,
        justificatifFormation: false,
        niveau: null,
        nomCollaborateur: null,
        prenomCollaborateur: null,
        userId: null,
        isPrincipal:null
      },
      required: false
    },
  },
  data() {
    return {
      showLoading: false,
      selectedAllParametresStandardUser: false,
      selectedAllParametres: 'Personnaliser',
      vertical: true,
      privilegeIds: [],
      allSelectedBySubInterface: [],
      allSelectedByInterface: [],

      // variable static
      permissionsData: [],
    }
  },
  watch: {
    selectedAllParametres: {
      handler(val) {
        console.log(this.selectedAllParametres)
          if (val === 'Administrateur') {

            this.showLoading = true
            this.privilegeIds = []
            this.allSelectedBySubInterface = []
            this.allSelectedByInterface = []
            this.allSelectedByInterface = [...this.permissionsData]
            this.allSelectedByInterface.forEach(el => {
              el.sub_interface.forEach(el1 => {
                this.allSelectedBySubInterface.push(el1)
                el1.privilege.forEach(el2 => {
                    this.privilegeIds.push(el2.id)
                })
              })
            })
            // remove law of signature (id=35) from array of laws ids in case of this.collaborateur.statut_juridique == "Associé"
            if(this.collaborateur.statut_juridique == "Associé" && !this.collaborateur.isPrincipal){
              this.privilegeIds = this.privilegeIds.filter((id)=>id !== 35)
            }
            this.showLoading = false
          } else if (val === 'Utilisateur standard') {
            this.privilegeIds = []
            this.allSelectedBySubInterface = []
            this.allSelectedByInterface = []
            this.getPermissionsByRole('Utilisateur Standard')
          } else if (val === 'Courtier') {
            this.privilegeIds = []
            this.allSelectedBySubInterface = []
            this.allSelectedByInterface = []
            this.getPermissionsByRole('Courtier')
          } else if (val === 'Salarie ou Mandataire') {
            this.privilegeIds = []
            this.allSelectedBySubInterface = []
            this.allSelectedByInterface = []
            this.getPermissionsByRole('Salarie ou Mandataire')
          } else if (val === 'Co-courtier ou Indicateur') {
            this.privilegeIds = []
            this.allSelectedBySubInterface = []
            this.allSelectedByInterface = []
            this.getPermissionsByRole('Co-courtier ou Indicateur')
          }
        
      }
    },
    collaborateur: {
      deep: true,
      handler(val) {
        if (val && this.courtierId) this.fetchPrivilegesByUser()
      }
    }
  },
  created() {
    this.fetchPermissionList()
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.vertical = window.innerWidth > 992
    })
  },
  methods: {
    toggleAllBySubInterface(checked, interfaceData, isSelected = true, subInterfaceId = null) {
      if (!isSelected) {
        const idsSelectedPrivileges = this.$_.map(subInterfaceId.privilege, 'id')
        idsSelectedPrivileges.forEach(el => {
          this.privilegeIds.forEach((el1, index) => {
            if (el1 === el) this.privilegeIds.splice(index, 1)
          })
        })
        return 0
      }
      this.selectedAllParametres = 'Personnaliser'
      checked.forEach(el1 => {
        if (this.allSelectedBySubInterface.findIndex(item => item.id == el1.id) <= -1) this.allSelectedBySubInterface.push(el1)
        el1.privilege.forEach(el2 => {
          if (!this.privilegeIds.includes(el2.id)) this.privilegeIds.push(el2.id)
        })
      })
      this.allSelectedBySubInterface.forEach(e => {
        const findInterface = this.allSelectedByInterface.find(item => item.id == e.interface_id)
        if (findInterface === undefined) {
          const filterSub = this.allSelectedBySubInterface.filter(item => item.interface_id == interfaceData.id).length
          if (interfaceData.sub_interface.length == filterSub) {
            this.allSelectedByInterface.push(interfaceData)
            return 0
          }
        } else {
          const filterSub = this.allSelectedBySubInterface.filter(item => item.interface_id == findInterface.id).length
          if (findInterface.sub_interface.length != filterSub) {
            const findInterfaceIndex = this.allSelectedByInterface.findIndex(item => item.id == e.interface_id)
            this.allSelectedByInterface.splice(findInterfaceIndex, 1)
            return 0
          }
        }
      })
    },
    toggleAllByInterface(checked) {
      const findInterfaceIndex = this.allSelectedByInterface.findIndex(item => item.id == checked.id)

      if (findInterfaceIndex > -1) {
        this.toggleAllBySubInterface(checked.sub_interface, checked)
      } else {
        this.removeTogglesSubinterface(checked)
      }
    },

    removeTogglesSubinterface(sub) {
      this.selectedAllParametres = 'Personnaliser'
      sub.sub_interface.forEach(el => {
        el.privilege.forEach(el1 => {
          const findIndexPrivilege = this.privilegeIds.findIndex(item => item === el1.id)
          if (findIndexPrivilege > -1) {
            this.privilegeIds.splice(findIndexPrivilege, 1)
          }
        })

        const findIndexSub = this.allSelectedBySubInterface.findIndex(item => item.id === el.id)
        this.allSelectedBySubInterface.splice(findIndexSub, 1)
      })
    },
    // Methods additional
    showModal() {
      this.$refs['modal-permission-collaborateur'].show()
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    // Methods fetch data
    fetchPermissionList() {
      this.showLoading = true
      this.$http
          .get('/permission/fetchPermissionList')
          .then(res => {
            if (res.data.success) {
              this.permissionsData = res.data.data
              this.sortDroitAccess()
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    fetchPrivilegesByUser() {
      this.showLoading = true
      this.privilegeIds = []
      this.allSelectedBySubInterface = []
      this.allSelectedByInterface = []
      this.selectedAllParametres = null;
      this.$http
          .get(`/permission/fetchPrivilegesByUser/${this.collaborateur.userId}/${this.courtierId}`)
          .then(res => {
            if (res.data.success) {
              this.privilegeIds = res.data.data
              if(this.collaborateur.is_droits_personaliser){
                this.selectedAllParametres = 'Personnaliser'
              }
              else if(this.collaborateur.isPrincipal || this.collaborateur.statut_juridique == 'Associé'){
                this.selectedAllParametres = 'Administrateur'
              }
              else if(this.collaborateur.statut_juridique){
                if(this.collaborateur.statut_juridique == 'Salarié' || this.collaborateur.statut_juridique == 'Mandataire' ){
                  this.selectedAllParametres = 'Salarie ou Mandataire'
                }else if(this.collaborateur.statut_juridique == 'Co-courtier' || this.collaborateur.statut_juridique == 'Indicateur' ){
                  this.selectedAllParametres = 'Co-courtier ou Indicateur'

                }
              }else{
                this.selectedAllParametres = 'Personnaliser'

              }
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          }).finally(() => {
            
          })
    },
    getPermissionsByRole(role) {
      console.log(role);
      this.showLoading = true
      this.$http
          .get(`/permission/getPermissionsByRole/${role}`)
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(element => {
                this.privilegeIds.push(element)
              })
              this.showLoading = false
              return 0
            }
            this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            this.privilegeIds = []
            this.showLoading = false
            return 0
          })
          .catch(err => {
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
            this.privilegeIds = []
            this.showLoading = false
            return 0
          })
    },

    // Method save data

    recordPrivileges() {
      this.showLoading = true
      this.$http
          .post('/permission/recordPrivileges', {
            privileges: this.privilegeIds,
            userId: this.collaborateur.userId,
            courtierId: this.courtierId,
            selectedParametre:this.selectedAllParametres,
            idPp:this.collaborateur.id
          })
          .then(res => {
            if (res.data.success) {
              if (this.collaborateur.userId == this.userId) {
                // this.fetchDataUserConnected()
              }
              this.showLoading = false
              this.messageToast(res.data.message, 'Succès', 'success')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'warning')
            // console.error(err.response.data.errors[0])
          })
    },
    fetchDataUserConnected() {
      this.$http
          .get(`/eca_global/fetchDataUserConnected/${this.userId}`)
          .then(res => {
            if (res.data.success) {
              localStorage.setItem('userData', JSON.stringify(res.data.data))
              // eslint-disable-next-line no-restricted-globals
              location.reload()
            }
          })
          .catch(err => {
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    sortDroitAccess(){
      let droits=[]
      droits.push(this.permissionsData[0])
      droits.push(this.permissionsData[2])
      droits.push(this.permissionsData[7])
      droits.push(this.permissionsData[4])
      droits.push(this.permissionsData[3])
      droits.push(this.permissionsData[1])
      droits.push(this.permissionsData[5])
      droits.push(this.permissionsData[6])
      droits.push(this.permissionsData[8])
      this.permissionsData=droits
    }
  }
}
</script>
