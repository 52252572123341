<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap">
      <div class="d-flex align-items-center mb-1 mt-1">
        <h1 class="text-primary font-weight-bolder text-uppercase ml-1">
          Détail du bordereau
        </h1>
      </div>
    </div>
    <b-overlay :show="showLoading" no-wrap />

    <div class="mr-1 mb-1">
      <b-card :id="'bordereau-card'" no-body class="card-revenue-budget text-dark" bg-variant="gradient-secondary"
        style="background-image: linear-gradient(45deg, #ffffff, #ffffff00)">
        <b-row class="mx-0">
          <b-col>
            <b-row>
              <b-col cols="12" class="mb-2">
                <span class="h3 font-weight-bolder">{{ bordereau.assureur.nom_com_assureur }}</span>
                <div class="mt-1">
                  <small class="text-muted d-block">Période {{ bordereau.date_debut}} - {{ bordereau.date_fin}}</small>
                </div>
              </b-col>
              <b-col cols="12">
                <b-row>
                  <b-col md="4" sm="12">
                    <div class="d-inline">
                    <span v-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) && Number(bordereau.montant_encaissement).toFixed(2)==Number(total).toFixed(2) && Number(bordereau.total_bordereau).toFixed(2)==Number(total).toFixed(2) " class="bullet bullet-success bullet-sm mr-1"/>
                    <span v-else-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) || Number(bordereau.montant_encaissement).toFixed(2)==Number(total).toFixed(2) || Number(bordereau.total_bordereau).toFixed(2)==Number(total).toFixed(2) " class="bullet bullet-warning bullet-sm mr-1"/>
                    <span v-else class="bullet bullet-danger bullet-sm mr-1"/>
                      
                    <span v-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) && Number(bordereau.montant_encaissement).toFixed(2)==Number(total).toFixed(2) && Number(bordereau.total_bordereau).toFixed(2)==Number(total).toFixed(2) " > Rapproché </span>
                    <span v-else-if="Number(bordereau.montant_encaissement).toFixed(2)==Number(bordereau.total_bordereau).toFixed(2) || Number(bordereau.montant_encaissement).toFixed(2)==Number(total).toFixed(2) || Number(bordereau.total_bordereau).toFixed(2)==Number(total).toFixed(2) " > Partiellement rapproché </span>
                    <span v-else>Non rapproché </span>
                    </div>
                  </b-col>
                  <b-col md="7" sm="4"  >
                    <b-row class="d-flex flex-row-reverse">
                      <div class="d-inline float-right text-danger" v-if="stats.absent>0">
                        <span> Contrats absents du bordereau : {{stats.absent}}</span>
                      </div>
                    </b-row>
                    <b-row class="d-flex flex-row-reverse">
                      <div class="d-inline float-right text-danger" v-if="stats.missing>0">
                        <span> Contrats manquants dans “Mes contrats” : {{stats.missing}}</span>
                      </div>
                    </b-row>
                  </b-col>

                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <div class="mt-3 ml-3 mb-3">
                    <span>  Montant encaissé : {{Number(bordereau.montant_encaissement).toFixed(2).replace(".", ",")}} €</span>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <div class="mt-3 ml-3">
                    <span>  Total bordereau : {{Number(bordereau.total_bordereau).toFixed(2).replace(".", ",")}} €</span>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <div class="mt-3 ml-3">
                    <span> Total importé : {{Number(total).toFixed(2).replace(".", ",")}} €</span>
                  </div>
                </b-row>
              </b-col>
              <b-col cols="3">
                <b-row>
                  <div class="mt-3 ml-3">
                    <span> Estimation/Prévisionnel : {{Number(bordereau.montant).toFixed(2).replace(".", ",")}} €</span>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

    </div>

    <div  class="mr-1 mb-1">
    <b-card no-body>
      <b-tabs card>
        <b-tab  active title="Détail du bordereau">
          <vue-good-table 
            class="w-100"
            :columns="columnsDetailsBordereau" 
            :rows="paiementDetailsRows" 
            :rtl="direction"
            style-class="vgt-table " :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: pageLength,
              rowsPerPageLabel: '',
              dropdownAllowAll: false,
              perPageDropdownEnabled: false,
              nextLabel: 'Suivant',
              prevLabel: 'Précédent',
              ofLabel: 'de',
              infoFn: params => ``
            }" 
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }" @on-selected-rows-change="selectionChanged">
            <div slot="emptystate">
              <center>Aucune donnée disponible dans le tableau</center>
            </div>
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field == 'actions' && !props.row.contrat">
                <span>
                  <b-button variant="transparent" @click="validateContrat(props.row,false)" v-b-tooltip.hover="'Ajouter à “Mes contrats“'" >
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                  </b-button>
              </span>
            </span>
            <span v-if="props.column.field === 'contrat_no'" >
              <div v-if="props.row.contrat">
                  <a style="text-decoration: underline;" @click="showContrat(props.row.contrat_id)">{{props.row.contrat_no}}</a>
              </div>
              <div v-else>
                <feather-icon icon="AlertTriangleIcon" class="mr-50" />{{props.row.contrat_no}}
              </div>
            </span>
            <span v-else-if="props.column.field === 'nom_complet'" >
              <div v-if="props.row.contrat">
                  <a style="text-decoration: underline;" v-if="props.row.contrat.contrable!=null">
                    <div v-if="props.row.contrat.contrable.nom!=null"  @click="showProspect(props.row.contrat.contrable_id,props.row.contrat.contrable_type)">{{props.row.contrat.contrable.nom}} {{props.row.contrat.contrable.prenom}}</div>
                    <div v-else @click="showProspect(props.row.contrat.contrable_id, props.row.contrat.contrable_type)">{{props.row.contrat.contrable.raison_sociale}}</div>
                  </a>
                  <!-- <a style="text-decoration: underline;" @click="showProspect(props.row.contrat.contrable_id)" v-else >{{props.row.nom_complet}}</a> -->
              </div>
              <span v-else>
                <span v-if="props.row.nom_complet">{{props.row.nom_complet}}</span>
                 <span v-else>{{props.row.nom_client}} {{props.row.prenom_client}}</span>
              </span>
            </span>
            <span v-else-if="props.column.field === 'mt_comm'" >
              <span>{{props.row.mt_comm ? Number(props.row.mt_comm).toFixed(2).replace(".", ",") : '0'}} €</span>
            </span>
            <span v-else-if="props.column.field === 'prime_ttc'" >
              <span>{{props.row.prime_ttc ? Number(props.row.prime_ttc).toFixed(2).replace(".", ",") : '0'}} €</span>
            </span>
            <span v-else-if="props.column.field === 'tx_comm'" >
              <span>{{props.row.tx_comm ? Number(props.row.tx_comm).toFixed(2).replace(".", ",") : '0'}} %</span>
            </span>
            <span v-else-if="props.column.field === 'prime_ht'" >
              <span>{{props.row.prime_ht ? Number(props.row.prime_ht).toFixed(2).replace(".", ",") : '0'}} €</span>
            </span>
            <span v-else>
                    {{ props.formattedRow[props.column.field] }}
            </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> de {{ props.total }} entrées </span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2 align-items-center">
                <b-col md="4">
                  <b-input-group>
                    <b-form-select v-model="selected" :options="options" />
                    <b-input-group-append>
                      <b-button  variant="primary" @click="action_masse(selected)">
                        <span>Valider </span>
                        <feather-icon icon="ArrowRightIcon" class="mr-50" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                    align="right" prev-class="prev-item" next-class="next-item"
                    @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </template>
          </vue-good-table>
        </b-tab>
        <b-tab  title="Contrats absents du bordereau">
          <vue-good-table 
            class="w-100"
            :columns="columnsContrat" 
            :rows="paiementDetailsMissing" 
            :rtl="direction"
            style-class="vgt-table " :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: pageLength,
              rowsPerPageLabel: '',
              dropdownAllowAll: false,
              perPageDropdownEnabled: false,
              nextLabel: 'Suivant',
              prevLabel: 'Précédent',
              ofLabel: 'de',
              infoFn: params => ``
            }" 
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'lignes sélectionnées',
              clearSelectionText: 'Effacer la sélection',
              disableSelectInfo: false,
              selectAllByGroup: false
            }" @on-selected-rows-change="selectionChanged">
            <div slot="emptystate">
              <center>Aucune donnée disponible dans le tableau</center>
            </div>
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'police_no'" >
                  <a style="text-decoration: underline;" @click="showContrat(props.row.id)">{{props.row.police_no}}</a>
              </span>
              <div v-else-if="props.column.field === 'nom_complet'">
                  <a style="text-decoration: underline;" @click="showProspect(props.row.contrable_id, props.row.contrable_type)">{{props.row.nom_complet}}</a>
                </div>
              <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <b-row class="mt-2 align-items-center">
                <b-col md="6" lg="5" xxl="3">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Affichage 1 à
                    </span>
                    <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                    <span class="text-nowrap"> de {{ props.total }} entrées </span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2 align-items-center">
                <b-col md="4">
                  <b-input-group>
                    <b-form-select v-model="selected" :options="options" />
                    <b-input-group-append>
                      <b-button  variant="primary" @click="action_masse(selected)">
                        <span>Valider </span>
                        <feather-icon icon="ArrowRightIcon" class="mr-50" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                    align="right" prev-class="prev-item" next-class="next-item"
                    @input="value => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </template>
          </vue-good-table>
        </b-tab>
      </b-tabs>
    </b-card>
    </div>
  </div>
</template>

<script>
import { BPagination, BFormGroup, BCard, BFormSelect,BTabs,BTab, BDropdown, BDropdownItem, BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BOverlay, BModal, BImg, VBToggle,VBTooltip } from 'bootstrap-vue'
// import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BOverlay,
    BCard,
    BButton,
    BCol,
    BRow,
    VueGoodTable,
    BPagination,
    BInputGroupAppend,
    BFormSelect,
    BTabs,
    BTab,
    BInputGroup,
    VBTooltip
    // eslint-disable-next-line vue/no-unused-components
    
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip':VBTooltip,
    Ripple
  },
  props: {
  },
  mounted(){
    this.showLoading = true
    this.borID=this.$route.params.id
    if(this.borID!=null)
      this.getData(this.borID)
    else 
      this.$router.push({ name: 'bordereaux'})

  },
  data() {
    return {
      currentUser:JSON.parse(localStorage.getItem('userData')),
      pageLength: 3,
      borID:0,
      dir: false,
      columnsDetailsBordereau: [
        { label: 'N° contrat', field: 'contrat_no', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Produit', field: 'produit', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Client', field: 'nom_complet', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Prime TTC', field: 'prime_ttc', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Prime HT', field: 'prime_ht', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Tx Comm', field: 'tx_comm', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Mt Comm', field: 'mt_comm', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'Description', field: 'Description', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: true, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
      columnsContrat:[
        {
          label: 'N° contrat',
          field: 'police_no',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Risque',
          field: 'risque',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Client',
          field: 'nom_complet',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Assureur',
          field: 'nom_com_assureur',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Source',
          field: 'source',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Date d\'effet',
          field: 'date_effet',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Prime TTC',
          field: 'prime',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Commercial',
          field: 'commercial_nom',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Concurrence',
          field: 'concurrence_statut',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rowsDocuments: [],
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      showLoading: false,
      selectedrows_masse: [],
      selected: null,
      options: [
        { value: null, text: 'Choisissez' },
        { value: 'Valider', text: ' Ajouter à “Mes contrats”' }
      ],
      total:0,
      bordereau: {},
      paiementDetails:[],
      paiementDetailsRows:[],
      paiementDetailsMissing:[],
      stats:{
        absent:0,
        missing:0
      }
      
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {

  },
  methods: {
    selectionChanged(params) {
      this.selectedrows_masse = params
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    downloadDocument(base64, name) {

    },
    clearDocumentDisplay() {

    },
    displayDocument(id, name, NomDuDocument) {
    },
    showContrat(idContrat){
      this.$router.push({ name: 'contrats-list', params: { id: idContrat ,type:"EXTERNE",interfaceContrat:"FicheContrat"} })
    },
    getData(id){
      this.showLoading= true
      this.$http.get('/bordereau/getBordereau/'+id)
      .then(res=>{
        if(res.data){
          this.bordereau=res.data
          this.bordereau.date_debut=this.changeFormatDateShortNormal(this.bordereau.date_debut)
          this.bordereau.date_fin=this.changeFormatDateShortNormal(this.bordereau.date_fin)
          this.$http.get('/bordereau/getSumPaiement/'+id).then(res=>{
            this.total=res.data
          })
          this.getMissingContrat(this.bordereau.assureur_id)
        }
      })
      // .catch(err=>{
      //   this.messageToast('une erreur est survenue', 'Erreur', 'error')
      // })
      this.$http.get('/bordereau/getBordereauDetails/'+id)
      .then(res=>{
        if(res.data.statut==200){
          // console.log(res)
          this.paiementDetails=res.data.data
          this.paiementDetailsRows=this.paiementDetails
          this.paiementDetailsRows.sort(function(a,b) {
            var date1= new Date(a.date_debut)
            var date2=new Date(b.date_debut)
            return date2-date1
          })
          this.stats.missing=this.paiementDetails.filter(row=>row.contrat_id==null).length
          this.showLoading=false
          
        }
      })
    },
    getMissingContrat(assureur){
      this.$http.post('/bordereau/getMissingContrats',{assureur_id:assureur,bordereau_id:this.borID})
      .then(res=>{
        if(res.data){
          this.paiementDetailsMissing=res.data.data
          this.stats.absent=this.paiementDetailsMissing.length
        }
      })
    },
    async action_masse(action) {
      switch (action) {
        case 'Valider':
          if (this.selectedrows_masse.selectedRows.length > 0) {
            this.$swal({
              text: 'Souhaitez vous créer ces contrat dans votre portefeuille (si le client existe déjà, le contrat lui sera rattaché) ?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            })
              .then((result) => {
                if (result.value) {
                  this.showLoading=true
                      this.validateContrat(this.selectedrows_masse.selectedRows,true)
                      this.$swal({
                       title :"Enregistré!",
                        icon:'success',
                        customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                        }
                  })
                }
              })
          }
          break
        default:

      }
    },
    async validateContrat(row,mass){
      // let details=this.paiementDetails.find(item=>item.contrat_no===contrat_no)
      // row.assureur_id=this.bordereau.assureur.id
      if(!mass){
      this.$swal({
      text: 'Souhaitez vous créer ce contrat dans votre portefeuille (si le client existe déjà, le contrat lui sera rattaché) ?',
      showDenyButton: true,
      icon:'warning',
      confirmButtonText: 'OUI',
      denyButtonText: `NON`,
      buttonsStyling: false,
      // customClass: {
      //   actions: 'my-actions',
      //   confirmButton: 'order-2',
      //   denyButton: 'order-3',
      // }
      customClass: {
                confirmButton: 'btn btn-primary',
                denyButton: 'btn btn-outline-danger ml-1 ',
              }
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$http.post('/bordereau/validateDetails',row).then(res=>{
            if(res.data){   
              this.$swal({
                title :"Enregistré!",
                icon:'success',
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              }
                })
              this.getData(this.borID)
            }
          })
        } 
        else if (result.isDenied) {
          this.$swal('Les modifications ne sont pas enregistrés', '', 'info')
        }
      })
    }
      else this.$http.post('/bordereau/validateDetails',row).then(res=>{
            if(res.data)
            this.getData(this.borID)
            else 
              this.$swal('Les modifications ne sont pas enregistrés', '', 'info')
          })

      
    },
    showProspect(idClient,type) {
      this.$router.push({
        name: 'tiers-list',
        params: {
          'id': idClient,
          'type': type == 'App\\Models\\PersonneMorale' ? 'PERSONNE_MORALE':'PERSONNE_PHYSIQUE',
          'interfaceChosen': type=='App\\Models\\PersonneMorale' ? 'Entreprise' : 'Professionnel'
        }
      })

    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style >
.denyButtonDanger{
    border: 1px solid #ea5455 !important;
    background-color: transparent; 
    color: #ea5455 !important;
    
}
.bordereau-card::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000029;
    z-index: 99;
}
my-actions{
  margin: 0em 0em 4em 4em;
}
.btn-primary{
  margin-right:2em;
  background-color: #4d25bc;
}
.order-3{
  margin-right:2em;
  background-color: white;
  border-color: red;
  border-width:1px;
  border-style: solid;
}
.order-3:hover{
  margin-right:2em;
  background-color: red;
  color: white;
  border-color: red;
  border-width:1px;
  border-style: solid;
}
</style>
