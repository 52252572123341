<template>
  <div>
    <b-sidebar id="sidebar-formation-collaborateur" bg-variant="white" sidebar-class="sidebar-lg" ref="sideBarFormationCollaborateur" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">{{ !isUpdateFormation ? 'Ajouter formation' : 'Modifier formation' }}</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="formationComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Formation data -->
            <h4 class="text-uppercase">Formation</h4>
            <div>
              <b-form-group label="Date de formation *" label-for="date-de-formation">
                <validation-provider #default="{ errors }" name="date de formation" rules="required">
                  <flat-pickr id="date-de-formation" v-model="composeData.formation.date" class="form-control" placeholder="Date de formation" :config="configDate" :class="errors.length > 0 ? 'is-invalid' : ''" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="L'objet de la formation *" label-for="h-objet-formation">
                <validation-provider #default="{ errors }" name="l'objet de la formation" rules="required">
                  <b-form-input id="h-objet-formation" v-model="composeData.formation.objet" placeholder="L'objet de la formation" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="NB heures *" label-for="h-nb-heures-formation">
                <validation-provider #default="{ errors }" name="NB heures" rules="required">
                  <b-form-input id="h-nb-heures-formation" type="number" v-model="composeData.formation.nb_heures" placeholder="NB heures" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group label="Au titre" label-for="h-au-titre-formation">
                <validation-provider #default="{ errors }" name="au titre" rules="required">
                  <b-form-select v-model="composeData.formation.au_titre" :options="auTitreOptions" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group> -->
            </div>

            <!-- Document justif -->
            <!-- <b-form-group label="Voulez-vous justifier la formation avec un document?" label-for="h-document-justify-question">
              <b-form-radio-group v-model="composeData.isJustyDocument" :options="isJustyDocumentOptions" />
            </b-form-group> -->
            <b-form-group v-if="isUpdateFormation" label="Voulez-vous modifier le document?">
              <b-form-radio-group v-model="composeData.isChangefile" :options="optionsIsChangefile" name="radio-inline-change-file" />
            </b-form-group>
            <div v-if="composeData.isChangefile && composeData.isJustyDocument">
              <h4 class="text-uppercase">Document justificatif</h4>

              <b-form-group label="Famille de document *" label-for="h-famille-document-justify">
                <validation-provider #default="{ errors }" name="famille de document" :rules="composeData.isChangefile && composeData.isJustyDocument ? 'required' : ''">
                  <b-form-select id="h-famille-document-justify" v-model="famille" :options="optionsFamille" :state="errors.length > 0 ? false : null" @change="changeListoptionType()">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Type de document *" label-for="h-type-document-justify">
                <validation-provider #default="{ errors }" name="type de document" :rules="composeData.isChangefile && composeData.isJustyDocument ? 'required' : ''">
                  <b-form-select id="h-type-document-justify" v-model="composeData.document.idType" :options="optionsType" :state="errors.length > 0 ? false : null">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Nom du document *" label-for="h-nom-document-document-justify">
                <validation-provider #default="{ errors }" name="nom du document" :rules="composeData.isChangefile && composeData.isJustyDocument ? 'required' : ''">
                  <b-form-input id="h-nom-document-document-justify" v-model="composeData.document.nom" placeholder="Nom du document" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Description" label-for="h-Description-document-justify">
                <b-form-textarea id="h-Description-document-justify" placeholder="Description" rows="3" v-model="composeData.document.description" />
              </b-form-group>

              <div v-if="composeData.isChangefile">
                <b-form-group class="mt-2" label="Document *" label-for="h-document-document-justify">
                  <validation-provider #default="{ errors }" name="document" :rules="composeData.isChangefile && composeData.isJustyDocument ? 'required' : ''">
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon" :variant="errors.length > 0 ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile">
                          <b-form-file type="file" id="fileautredocument-document-justify" class="d-none" v-model="composeData.document.file" />
                          <feather-icon icon="UploadCloudIcon" />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-input disabled placeholder="Choisissez un document" :value="composeData.document.file ? composeData.document.file.name : ''" :state="errors.length > 0 ? false : null" />
                    </b-input-group>

                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordFormation"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BFormTextarea, BInputGroupPrepend, BFormFile, VBTooltip, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BFormRadioGroup, BForm, BInputGroup, BFormInvalidFeedback, BFormSelect, BFormSelectOption, BAvatar, BBadge, BInputGroupAppend } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import documentService from '@/shared/services/documentService'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupAppend,
    BFormTextarea,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BForm,
    BFormSelectOption,
    BInputGroup,
    BFormInvalidFeedback,
    BBadge,
    BFormRadioGroup,
    Cleave,
    vSelect,
    BFormSelect,
    flatPickr,
    BAvatar,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    dataSelectOnlyOneFormation: {
      type: Object,
      default: null,
      required: false
    },
    isUpdateFormation: {
      type: Boolean,
      default: false,
      required: true
    },
    updateFormation: {
      type: Object,
      default: null,
      required: false
    },
    dataSelectedForEditFormation: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {
        attestationHonorabilite: false,
        curriculumVitae: false,
        idQualification: '',
        justificatifFormation: false,
        niveau: '',
        nomCollaborateur: '',
        prenomCollaborateur: '',
        userId: ''
      },
      required: true
    },
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    optionsFamille: {
      type: Array,
      default: [],
      required: true
    }
  },
  data() {
    return {
      optionsIsChangefile: [
        { text: 'Non', value: false },
        { text: 'Oui', value: true }
      ],
      required,
      // isUpdateAction: false,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            var ele = document.activeElement
            var val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },

      auTitreOptions: [
        { text: '-- Choisissez --', value: null },
        { text: 'Courtier', value: 'COURTIER' }
      ],
      isJustyDocumentOptions: [
        { text: 'Non', value: 0 },
        { text: 'Oui', value: 1 }
      ],
      famille: null,
      optionsType: [],
      composeData: {
        formation: {
          id: '',
          date: '',
          objet: '',
          nb_heures: '',
          au_titre: 'Courtier',
          user_id: this.dataSelectedForEditFormation.userId
        },
        document: {
          id: '',
          nom: '',
          description: '',
          idFamille: '',
          idType: '',
          file: null
        },
        courtierUser: {
          courtier_id: this.courtierId,
          user_id: this.dataSelectedForEditFormation.userId
        },
        isJustyDocument: 1,
        isChangefile: true
      }
    }
  },

  watch: {
    dataSelectOnlyOneFormation: {
      deep: true,
      handler(val) {
        if (this.isUpdateFormation) {
          this.getDataForUpdate(val)
        } else {
          this.clearDataSideBar()
        }
      }
    },
    isUpdateFormation: {
      handler(val) {
        if (!val) {
          this.clearDataSideBar()
          this.composeData.isChangefile = true
        } else {
          this.getDataForUpdate(this.dataSelectOnlyOneFormation)
          this.composeData.isChangefile = false
        }
      }
    },
    'composeData.isJustyDocument': {
      handler() {
        this.clearDocument()
      }
    },
    dataSelectedForEditFormation: {
      deep: true,
      handler(val) {
        this.composeData.formation.user_id = val.userId
        this.composeData.courtierUser.user_id = val.userId
      }
    }
  },
  methods: {
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    fetchFormationByCourtierAndId(dataSelectedForEditFormation) {
      this.clearDataSideBar()
      this.$http
        .post('/formation/fetchFormationByCourtierAndId', { courtierId: dataSelectedForEditFormation.courtierId, idQualification: dataSelectedForEditFormation.qualificationId, userId: dataSelectedForEditFormation.userId })
        .then(res => {
          if (res.data.success) {
            this.composeData = res.data.data
            // this.isUpdate = true
          } else {
            this.messageToast("Nous n'avons pas pu trouver la ressource que vous avez demandée.", 'Erreur', 'warning')
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    hideSideBare() {
      this.$refs.sideBarFormationCollaborateur.hide()
    },
    getDataForUpdate(val) {
      this.composeData.formation.id = val.formationId
      this.composeData.formation.date = val.date
      this.composeData.formation.objet = val.objet
      this.composeData.formation.nb_heures = val.nbHeures
      this.composeData.formation.au_titre = val.auTitre
      this.composeData.formation.user_id = this.dataSelectedForEditFormation.userId

      this.composeData.courtierUser.courtier_id = this.courtierId
      this.composeData.courtierUser.user_id = val.userId

      this.composeData.isJustyDocument = 1

      this.composeData.document.id = val.idDocument
      this.composeData.document.nom = val.NomDuDocument
      this.composeData.document.description = val.Description ? val.Description : ''
      this.composeData.document.idFamille = val.familleDeDocument
      this.famille = this.optionsFamille.find(item => item.value.id === val.familleDeDocument).value
      this.changeListoptionType()
      this.composeData.document.idType = val.typeDeDocument
      this.composeData.isChangefile = false
    },
    clearDocument() {
      this.composeData.document.id = ''
      this.composeData.document.nom = ''
      this.composeData.document.description = ''
      this.composeData.document.idFamille = ''
      this.composeData.document.idType = ''
      this.composeData.document.file = null
      this.famille = ''
      this.optionsType = []
    },
    clearDataSideBar() {
      this.composeData.formation.id = ''
      this.composeData.formation.date = ''
      this.composeData.formation.objet = ''
      this.composeData.formation.nb_heures = ''
      this.composeData.formation.au_titre = 'Courtier'
      this.composeData.formation.user_id = this.dataSelectedForEditFormation.userId
      this.clearDocument()
      this.composeData.courtierUser.courtier_id = this.courtierId
      this.composeData.courtierUser.user_id = this.dataSelectedForEditFormation.userId

      this.composeData.isJustyDocument = 1
      this.composeData.isChangefile = true

      this.$refs.formationComposeRules.reset()
    },
    openToSelectFile: () => {
      document.getElementById('fileautredocument-document-justify').click()
    },
    changeListoptionType() {
      if (this.famille) {
        this.composeData.document.idFamille = this.famille.id
        this.composeData.document.idType = ''
        this.optionsType = []
        this.famille.typeDocument.forEach(el => {
          this.optionsType.push({
            value: el.id,
            text: el.type
          })
        })
      } else {
        this.composeData.document.idFamille = ''
        this.composeData.document.idType = ''
        this.optionsType = []
      }
    },

    // record Data formation
    recordFormation() {
      this.$refs.formationComposeRules.validate().then(success => {
        if (success) {
          // Set formData
          const formData = new FormData()
          // Append the rest of your form data

          formData.append('idDocument', this.composeData.document.id)
          formData.append('document[file]', this.composeData.document.file ? this.composeData.document.file : '')
          formData.append('document[idType]', this.composeData.document.idType)
          formData.append('document[nom]', this.composeData.document.nom)
          formData.append('document[description]', this.composeData.document.description)

          formData.append('courtierUser[courtierId]', this.composeData.courtierUser.courtier_id)
          formData.append('courtierUser[userId]', this.composeData.courtierUser.user_id)

          formData.append('idFormation', this.composeData.formation.id)
          formData.append('formation[date]', this.composeData.formation.date)
          formData.append('formation[objet]', this.composeData.formation.objet)
          formData.append('formation[nb_heures]', this.composeData.formation.nb_heures)
          formData.append('formation[au_titre]', this.composeData.formation.au_titre)
          formData.append('formation[courtier_id]', this.composeData.courtierUser.courtier_id)
          formData.append('formation[user_id]', this.composeData.formation.user_id)

          formData.append('isJustyDocument', this.composeData.isJustyDocument)
          formData.append('isChangefile', this.composeData.isChangefile)
          formData.append('idQualification', this.dataSelectedForEditFormation.idQualification)

          // send request
          const config = { headers: { 'Content-Type': 'multipart/form-data' } }
          this.$http
            .post('/collaborateur/recordFormationCollaborateur', formData, config)
            .then(res => {
              if (res.data.success) {
                // if (res.data.data !== 'Succès') {
                //   this.$emit('update-qualification-front', res.data.data[0], this.dataSelectedForEditFormation.idQualification)
                // }
                const message = this.composeData.document.id ? 'Les modifications ont été faites avec succès.' : 'La formation a été créée avec succès.' 
                this.$emit('update-formation-list', res.data.data[0], this.dataSelectedForEditFormation.idQualification, message)
                this.clearDataSideBar()
                this.hideSideBare()
                // this.messageToast(res.data.message, 'Succès', 'success')
              }
            })
            .catch(err => {
              this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
              console.error(err)
            })
        }
      })
    }
  },

  // setup() {
  //   const optionsFamille = ref([])

  //   const fetchDocumentNatureWithTypes = documentService()
  //   fetchDocumentNatureWithTypes
  //     .then(success => {
  //       optionsFamille.value = []
  //       success.data.forEach(el => {
  //         optionsFamille.value.push({
  //           value: { id: el.id, typeDocument: el.documentype },
  //           text: el.libelle
  //         })
  //       })
  //     })
  //     .catch(errors => {
  //       console.log(errors)
  //       optionsFamille.value = []
  //     })

  //   return {
  //     fetchDocumentNatureWithTypes,
  //     optionsFamille
  //   }
  // }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
