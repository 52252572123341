<template>
  <div>
    <div>
      <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-1 mt-1">
            <h1 class="text-primary font-weight-bolder text-uppercase ml-1">
              MES COMMISSIONS 
            </h1>
          </div>
          <div class="mr-1 mb-1"  >
            <add-bordereau @refresh="getBordereauList" :typeBordereauOptions="typeBordereauOptions" :bordID="bordereau.id" :compagnieOptions="compagnieOptions" @addRequest="getAction"/>
          </div>
      </div>
      <b-card v-if="isPrincipal() || can('List contracts of broker') || isPrincipal() || can('List contracts by commercial')" title="FILTRES">
        <div class="custom-search">
            <!-- advance search input -->
            <b-row>
              <b-col md="2" ml="2">
                <b-form-group label="Assureur" label-for="assureur">
                  <b-form-select id="assureur" v-model="search.assureur" :options="assureursOptions"/>
                </b-form-group>
              </b-col>
              <b-col md="2" ml="2">
                <b-form-group label="Mois du bordereau" label-for="mois">
                  <b-form-select id="mois_bordereau" v-model="search.mois" :options="moisOptions"/>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Statut" label-for="statut">
                  <b-form-select id="statut" v-model="search.status" :options="statusOptions"/>
                </b-form-group>
              </b-col>
              <b-col md="1" class="mt-1">
                <b-button v-b-tooltip.hover.top="'Réinitialiser'"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="btn-icon mt-1"
                          variant="primary"
                          @click="clearAllFilter"
                >
                  <feather-icon icon="RefreshCwIcon"/>
                </b-button>
              </b-col>
            </b-row>
        </div>
      </b-card>
      <div>
      
      <b-overlay :show="showLoading" no-wrap/>
      <bordereau-card v-for="(b, i) in tempBordereau" :key="i"
                              :bordereau="b"
                              :index="i"
                              :courtier-id="currentUser.courtier_user[0].courtier_id"
                              :user-id="currentUser.courtier_user[0].user_id"
                              @modify="getActionRequested"
                              @upload="uploadFileAction"

      />
      <div v-if="tempBordereau.count>0">
        <b-row class="mx-0">
        <b-col  class="revenue-report-wrapper">
          <b-row>
            <b-col cols="12" class="mb-2">
              Aucun bordereau disponible
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <add-document-file :bordereau="bordereau" id="bordereau" :courtierId="currentUser.courtier_user[0].courtier_id" :userId="currentUser.courtier_user[0].user_id" />
      <!-- @select-user-for-permission="selectUserForPermission"
                              @create-document-collaborateur="createDocumentCollaborateur"
                              @update-formation="updateFormation"
                              @update-formation-list="updateFormationList" @update-collaborateur="updateCollaborateur"
                              @formation-collaborateur="formationCollaborateur" -->
    </div>
    </div>
  </div>
</template>
<script>
import {
BAvatar,
BBadge,
BButton,
BCard,
BDropdown,
BDropdownItem,
BForm,
BFormGroup,
BFormInput,
BFormSelect,
BCol,
BFormSelectOption,
BInputGroup,
BInputGroupAppend,
BModal,
BOverlay,
BPagination,
BRow,
VBToggle,
VBTooltip,
BFormInvalidFeedback
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import BordereauCard from './BordereauCard.vue'

import AddBordereau from './AddBordereau.vue'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ActionsCollaborateurVue from '../outils/gestion-collaborateurs/components-actions-collaborateurs/ActionsCollaborateur.vue'
import ActionsFormationCollaborateurVue
from '../outils/gestion-collaborateurs/components-actions-collaborateurs/formation-collaborateur/ActionsFormationCollaborateur.vue'
// import ActionsDocumentVue from '../gestion-cabinet/autres-documents/ActionsDocument.vue'
import ActionsDocumentCollaborateur from '../outils/gestion-cabinet/autres-documents/ActionsDocumentCollaborateur.vue'
import ActionsDroitsVue from '../outils/gestion-collaborateurs/components-actions-collaborateurs/droits-collaborateurs/ActionsDroits.vue'
import AddDocumentFile from './AddDocumentFile.vue'

export default {
components: {
  BBadge,
  BInputGroup,
  VBTooltip,
  BOverlay,
  BInputGroupAppend,
  BAvatar,
  BForm,
  BFormSelectOption,
  BPagination,
  BFormGroup,
  BFormInput,
  BCol,
  BFormSelect,
  flatPickr,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BModal,
  BRow,
  ValidationProvider,
  ValidationObserver,
  BFormInvalidFeedback,
  ToastificationContent,
  AddBordereau,
  BordereauCard,
  ActionsCollaborateurVue,
  ActionsFormationCollaborateurVue,
  // ActionsDocumentVue,
  ActionsDocumentCollaborateur,
  ActionsDroitsVue,
  AddDocumentFile,
},
directives: {
  'b-toggle': VBToggle,
  'b-tooltip': VBTooltip,
  Ripple,
},
props:{
},
watch: {
  search:{
    deep: true,
    handler(){
      this.filterData()
    }
  }
},
data() {
  return {
    showLoading:true,
    currentUser: JSON.parse(localStorage.getItem('userData')),
    listBordereau:[],
    tempBordereau:[],
    selectedBordereau:null,
    search:{
      assureur:null,
      status:null,
      mois:null
    },
    assureursOptions: [{
      value: null,
      text: '-- Choisissez --',
      disabled: false
    }],
    compagnieOptions:[{
      value: null,
      text: '-- Choisissez --',
      disabled: false
    }],
    moisOptions: [{
      value: null,
      text: '-- Choisissez --',
      disabled: false
    }],
    statusOptions: [{
      value: null,
      text: '-- Choisissez --',
      disabled: false
    },
    {
      value: 'rap',
      text: 'Rapproché'
    },
    {
      value: 'nrap',
      text: 'Non rapproché'
    },
    {
      value: 'prap',
      text: 'Partiellement rapproché'
    }
    ],
    typeBordereauOptions: [{
        value: null,
        text: '-- Choisissez --',
        disabled: true,
      },
      {
        value: '1',
        text: 'option 1'
      },
      {
        value: '2',
        text: 'option 2'
      },
      ],
    bordereau:{
      montant:null,
      compagnie:null,
      date_creation:null,
      date_fin:null,
      typeBordereau:null,
      banque:null,
      date_encaissement:null,
      montant_encaisse:null,
      fileBordereau:null,
    },
    without:[],
    missing:[],
    selectedBordereau:{},
    currentUser:JSON.parse(localStorage.getItem('userData')),
  }
},
created() {
  this.getBordereauList()
  this.getCompagnie()
  this.fillmounths()
},
methods:{
  getBordereauList(){
    this.$http.get('/bordereau/getAll')
    .then(res=>{
      if(res.data.status==200){
        this.showLoading=true
        this.listBordereau=res.data.data
        this.without=res.data.stats.without
        this.missing=res.data.stats.missing
        this.initData()
      }
    })
    .catch(err=>{

    })
  },
  fillmounths() {
      for (let i = 1; i < 13; i++) {
        this.moisOptions.push({
          value: i,
          text: i >= 10 ? i + ' - ' + this.getMonthName(i) : ("0" + i) + ' - ' + this.getMonthName(i),
        })
      }
    },
  getMonthName(monthNumber) {
    const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    return months[monthNumber - 1];
  },
  clearAllFilter() {
      this.search.assureur = null
      this.search.mois = null
      this.search.status = null
      this.tempBordereau=this.listBordereau
    },
  initData(){
    let i=0
    let checked=false
    var bordID=1
    // console.log(this.without)
    for(let id in this.listBordereau){
      bordID=this.listBordereau[id].id
      this.listBordereau[id].missing=this.missing.find((item)=>item.bordereau_id==bordID)??0
      this.listBordereau[id].without=this.without.find((item)=>item.bordereau_id==bordID)??0
      if(this.assureursOptions.length<=1)
        checked=true
      if (this.assureursOptions[i].text.localeCompare(this.listBordereau[id].assureur.nom_com_assureur)!=0 && checked){
        this.assureursOptions.push({
            value: this.listBordereau[id].assureur.id,
            text: this.listBordereau[id].assureur.nom_com_assureur,
          })
          i++
      }
      
    }
    this.showLoading=false
    this.tempBordereau = this.listBordereau
    this.assureursOptions = Array.from(new Set(this.assureursOptions.map(a => a.value)))
      .map(id => {
        return this.assureursOptions.find(a => a.value === id)
    })
    this.assureursOptions.sort(function (a, b) {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    }); 
  },
  filterData(){
    let tempBord=[]
    this.showLoading=true
    tempBord = this.listBordereau
    // console.log(tempBord)
    if (this.search.mois) tempBord = tempBord.filter(item =>{
      let month=item.date_debut.split('-')
      return this.search.mois===parseInt(month[1])
    } )
    if (this.search.assureur) tempBord = tempBord.filter(item => {
      if(item.assureur_id == this.search.assureur){
        return item.assureur_id === this.search.assureur
      }
      
    })
    if (this.search.status && this.search.status=="rap") tempBord = tempBord.filter(item => {
        return Number(item.montant_encaissement).toFixed(2)==Number(item.total_bordereau).toFixed(2) && Number(item.montant_encaissement).toFixed(2)==Number(item.importer).toFixed(2) && Number(item.total_bordereau).toFixed(2)==Number(item.importer).toFixed(2)
    })
    if (this.search.status && this.search.status=="prap") tempBord = tempBord.filter(item => {
      return Number(item.montant_encaissement).toFixed(2)==Number(item.total_bordereau).toFixed(2) && Number(item.montant_encaissement).toFixed(2) !=Number(item.importer).toFixed(2) || Number(item.importer).toFixed(2)==Number(item.total_bordereau).toFixed(2) && Number(item.montant_encaissement).toFixed(2) !=Number(item.importer).toFixed(2)
    })
    if (this.search.status && this.search.status=="nrap") tempBord=tempBord.filter(item=>{
      return Number(item.montant_encaissement).toFixed(2)!=Number(item.total_bordereau).toFixed(2) && Number(item.montant_encaissement).toFixed(2)!=Number(item.importer).toFixed(2) && Number(item.total_bordereau).toFixed(2)!=Number(item.importer).toFixed(2)
    
   })
    if (!this.search.assureur && !this.search.status && !this.search.mois ) this.clearAllFilter()
    this.showLoading=false
    this.tempBordereau=tempBord
  },
  getActionRequested(updatedBordereau){
    this.bordereau=updatedBordereau
  },
  getAction(){
    this.bordereau={}
  },
  uploadFileAction(selectedBordereau){
    this.bordereau=selectedBordereau
  },
  async getCompagnie(){
    await this.$http
    .get('/assureurs/getAll')
    .then(res=>{
      if(res.data){
        let assureurs=res.data.data
        for(let i in assureurs)
          this.compagnieOptions.push({
            value: assureurs[i].id,
            text: assureurs[i].nom_com_assureur,
          })
      }
    })
    .catch(err => {
        console.log(err)
      })
  },
}

}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
.flatpickr-current-month .flatpickr-monthDropdown-months span, .flatpickr-current-month .numInputWrapper span
{
display: block !important;
}
</style>
